import { createSlice } from '@reduxjs/toolkit'

export const zoomSlice = createSlice({
    name: 'auth/zoom',
    initialState: {
        accessToken: '',
        refreshToken: '',
        // code:"",
        // isAuthorized: false,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        setToken: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        }
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setAccountBlock } =
    zoomSlice.actions

export default zoomSlice.reducer
