import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import organization from './organizationSlice'
import zoom from './zoomSlice'

const reducer = combineReducers({
    session,
    zoom,
    user,
    organization
})

export default reducer
